.container{
    background: #F8F9FE;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 40px 40px ;
    gap: 20px;

}
.subContainer{
    width: 80%;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.heading{
    color: #000;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 56px */
margin: 0px;
}
.subHeading{
    color: #000;
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 28px */
margin: 0px;
width: 90%;
}


.button{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 22.4px */
letter-spacing: 0.2px;
border-radius: 10px;
background: #fff;
padding: 10px 20px;
border: 1px solid #005068
}

.secondContainer{
    width: 80%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}
.cardHeading{
    color: #000;
font-family: Lato;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 42px */
margin: 0px;
}

.cardContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* justify-content: flex-start;
    align-items: flex-start;
    background: #FFF;
    padding: 0px 0px 20px 0px; */
}
.card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #FFF;
    padding: 0px 0px 20px 0px;
    width: 50%;
    box-shadow: 0px 4px 4px 0px #00000040;

}

.cardImg{
    width: 100%;
    /* height: 116px; */
}
.cardTitle{
    color: #000;
font-family: Lato;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 177.778% */
margin: 0px;
padding: 0px 5px;
/* text-align: start; */
text-wrap: wrap;
/* border: 1px solid red; */
width: 100%;
}
.cardDesc{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
opacity: 0.5;
width: 100%;
padding:0px 5px ;
text-align: start;
text-wrap: wrap;
word-wrap: break-word;
overflow-wrap: break-word;
white-space: normal;
}

@media (min-width:993px) and (max-width:1199px){
   
    .heading{
    font-size: 30px;
    }
    .subHeading{
    font-size: 18px;
    
    }
    .cardHeading{
    font-size: 24px;
    }
    .card{
        padding: 0px 0px 10px 0px;
        
    }
    .cardTitle{
    font-size: 16px;
    line-height: 24px;
    }
    .cardDesc{
    font-size: 14px;
    line-height: 20px; 
    }
}
@media (min-width:769px) and (max-width:991px){
    .container{
        gap: 20px;
    }
    .subContainer{
        width: 90%;
    }
    .heading{
    font-size: 30px;
    }
    .subHeading{
    font-size: 18px;
    width: 80%;
    }
    .cardHeading{
    font-size: 24px;
    }
    .card{
        padding: 0px 0px 10px 0px;
        
    }
    .cardImg{
        width: 100%;
        /* height: 116px; */
        /* width: 150px; */
    }
    .cardTitle{
    font-size: 14px;
    line-height: 20px;
    min-height: 40px;
    }
    .cardDesc{
    font-size: 12px;
    line-height: 20px; 
    }
}
@media (max-width:768px){
    .container{
        gap: 10px;
        padding: 20px 0px 40px 0px;
    }
    .subContainer{
        width: 95%;
    }
    .heading{
        font-size: 24px;
        }
    .subHeading{
        font-size: 16px;
        width: 100%;
        }
        .secondContainer{
            width: 95%;
        }
        
            .cardHeading{
            font-size: 24px;
            }
    .scrollableContainer {
    
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
      }
      .cardContainer{
        /* display: flex; */
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        display: inline-flex;
        flex: 0 0 auto; 
        margin-right: 10px; 
        width: auto;
    }
    .card{
        width: 240px;
        padding: 0px 0px 10px 0px;
        gap: 10px;
    }
    .cardTitle{
        font-size: 16px;
        line-height: 20px;
        }
        .cardDesc{
        font-size: 12px;
        line-height: 20px; 
        }
}
