.container{
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subContainer{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
}
.heading{
    color: #000;
font-family: Lato;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 56px */
margin: 0px;
}
.subHeading{
    color: #000;
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 44.8px */
margin: 0px;
}

.faqContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    /* border: 1px solid white; */
  }
  .leftFaqContainer{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px 10px 10px;

  }
  .topicHeading{
    color: #000;
font-family: Lato;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 26.4px */
  }
  
  .topics {
    flex: 1;
    padding-right: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .topic {
    cursor: pointer;
    transition: background-color 0.3s;
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 19.2px */
letter-spacing: 0.032px;
  }
  
  .topic.active {
opacity: 0.5;
  }
  
  .questions {
    flex: 2;
    color: #000;
  }
  
  .question {
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.30);
    /* border-radius: 4px; */
    color: #000;
  }
  
  .questionHeader {
    display: flex;
    /* justify-content: space-between; */
    cursor: pointer;
    gap: 10px;
  }
  .questionText{
    color: #000;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 24px */
text-align: start;
  }
  
  .toggleIcon {
    font-weight: bold;
    margin-left: 10px;
  }
  
  .answer {
    margin-top: 10px;
    margin-left: 30px;
    text-align: start;
    color: #000;
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
  }
  .topicDropdown {
    display: none  ;
    cursor: pointer;
    width: 70%;
    padding: 10px 10px 10px 10px;
    margin-top: 10px;
    /* border: 1px solid #fff; */
    border-radius: 5px;
    background-color: transparent;
    color: #000;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.032px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
      background-image:
      linear-gradient(45deg, transparent 50%, #0d0d0d 50%, #0d0d0d),
    linear-gradient(135deg, #0d0d0d 50%, transparent 50%, transparent);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
    
  }
  
  /* select.topicDropdown {
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #fff, #fff) ;
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
  } */
 
  
 


  @media (min-width:993px) and (max-width:1199px){
    .heading{
  font-size: 30px;
  }
  .subHeading{
font-size: 28px;
}
.questionText{
  color: #000;
font-family: Lato;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 24px */
text-align: start;
}
}
  @media (min-width:769px) and (max-width:992px){
    .heading{
  font-size: 30px;
  }
  .subHeading{
font-size: 28px;
}
.questionText{
  color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 24px */
text-align: start;
}

}
  @media  (max-width:768px){
    .subContainer{
      width: 95%;
      

  }
    .heading{
  font-size: 24px;
  }
  .subHeading{
font-size: 20px;
}
.questionText{
font-size: 14px;
}
.topicDropdown {
  display: flex;
  font-size: 14px;
  /* border: 1px solid black; */
  /* padding: 10px; */
}
.selectOptions{
  font-size: 20px;
}
.topics {
  flex: 1;
  padding-right: 20px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.faqContainer {
  width: 100%;
  flex-direction: column;
  
}
.leftFaqContainer{
  width: 50%;
 
}
.topicHeading{
display: none;
}

}