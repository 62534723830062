.container{
    background:#000;
    background:#F8F9FE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0px 100px 0px;

}
.subContainer{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}
.title{
    color: #000;
font-family: Lato;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 50.4px */
margin: 0px;
width: 70%;
text-align: start;
}

.descContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.descDetailsContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.desc{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
text-align: start;
width: 80%;
margin: 0px;
word-wrap: break-word;
overflow-wrap: break-word;
}

.benifitsContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.benifitsDetails{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    gap: 10px;
}
.benifits{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 22.4px */
margin: 0px;
}
.image{
    width: 50%;
}

@media (min-width:993px) and (max-width:1199px){
   
    .title{
    font-size: 30px;
    width: 80%;
    }
}
@media (min-width:769px) and (max-width:992px){
    .subContainer{
        width: 90%;
        gap: 20px;
        
    }
    .title{
    font-size: 30px;
    width: 80%;
    }
    .descContainer{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    .desc{
    width: 100%;
    }
}
@media  (max-width:768px){
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 10px 30px 10px;
    
    }
    .subContainer{
        width: 95%;
        gap: 20px;
        
    }
    .title{
    font-size: 24px;
    width: 100%;
    text-align: center;
    }
    .descContainer{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    .desc{
    width: 100%;
    }
    .image{
        width: 100%;
    }
}
