.header{ 
    color: #000; 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px; 
    /* border: 0, 0, .5px, 0 ; */
    /* border-bottom-color:  #BFBFBF; */
    /* border-bottom-style: solid; */
  }
  .logo{
    width: 180px;
    height: 70px;
    

  }
  /* .header::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -2px;
    width: 100%;
    height: 5px;
    background: url("../../image/homePage/headingBottom.svg") center bottom no-repeat;
    border: 1px solid red;
  } */
  .navbarMenu{
    /* width: 60%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* border: 1px solid red; */
  }
  
  .buttonTitle{
    /* margin-right: 1.5rem; */
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  color: #000;
  border: 0;
  /* padding: 0.5rem 0 0.5rem  0rem; */
  cursor: pointer; 
  transition: color 0.3s ease-in-out;
  /* border: 1px solid red; */
  }
  
  .buttonTitle:hover {
      /* opacity: 0.7;  */
      color: #b9b9b9;
    }

    .buttonTitle.active {
      font-weight: 700;
      text-decoration: none;
      background-size: auto;
      
    }
    .matchedId {
      border-bottom: 2px solid #000;
    }
    .cancelIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    
    /* Default styles for the hamburger menu */
  .hamburgerMenu {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    /* border: 1px solid red; */
  }
  
  .hamburgerMenu.open {
    transform: translateX(0);
    /* border: 1px solid red; */
  }
  
  .hamburgerMenuContent {
    background-color: white;
    padding: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* border: 1px solid red; */
  }
  
  .hamburger {
    display: none;
  }
  .navbarMenu{
    display: flex;
  }
  
  
  @media (max-width: 992px) {
    .header {
      justify-content: space-between;
    }
  
    .hamburgerMenu {
      display: block;
    }
  
    .hamburger {
      display: block;
      width: 5%;
      
    }
    .navbarMenu{
      display: none;
    }
    .buttonTitle{
      margin-bottom: 20px;
    }
    .logo{
      width: 150px;
      height: 70px;
      
  
    }
  
  }
  
    
    
    
    
    
  