
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9998; /* Ensure it's above everything else */
  }

  .mainContainer{
    z-index: 999999; /* Ensure it's above everything else */
  }

.container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 999999; /* Ensure it's above everything else */
}

.title{
    font-size: 26;
    font-weight: 700;
    line-height: 36px;
    margin: 0px;
}

.description{
    font-size: 16;
    font-weight: 400;
    line-height: 25px;
    margin: 0px;
}

.label{
    font-size: 14px;
    font-weight: 700;
    padding: 2px;
    margin-bottom: 3px;
    display: flex;
    align-items: center; /* Align asterisk vertically */
}
.labelMessage{
    font-size: 14px;
    font-weight: 700;
    padding: 2px;
    margin-bottom: 3px;
    display: flex;
    align-items: center; /* Align asterisk vertically */
}
.label::after {
    content: "*"; /* Add an asterisk before the label text */
    color: #000; /* Set the color of the asterisk to red or your preferred color */
    margin-left: 3px; /* Adjust the spacing between the asterisk and label text */
  }

.inputStyle{
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    padding: 8px;
    width: 4rem;
    border: 1px solid #cecbcb;
    border-radius: 4px;
    resize: none;
}
.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.cancelButton{
    padding: 5px 30px;
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    outline: none;
    border: 1px solid #000;
}
.cancelButton:hover{
    background-color: #e2e2e2;
    color: #000;
    border: 1px solid #2a272a;
}
.submitButton{
    padding: 5px 30px;
    background-color: #000;
    color: #fff;
    border-radius: 4px;
    outline: none;
    border: 1px solid #fff;
}

.submitButton:hover{
    background-color: #2a272a;
    color: #fff;
    border: 1px solid #fff;
}
.fileUpload{
    margin-top: 10px;
}
.p-fileupload-choose {
    display: none; /* Hide the default "Choose" button */
  }
  
  .custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 4px;
  }
  
  .custom-file-upload:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }