.container{
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 30px 0px 80px 0px;
}

.benifitsContainer{
    width: 75%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
}
.benifitsHeading{
    font-size: 36px;
    font-weight: 500;
    margin: 0px;
}
.benifitsSubHeading{
    font-size: 40px;
    font-weight: 700;
    margin: 0px;
}


.cardsContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    
}
.cardWraper{
    padding: 5px;
    
}

.customCard{
  gap: 15px;
    padding: 20px 10px;
    width: 340px;
    /* height: 240px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;  
}
.cardTitle{
    font-size: 18px;
    font-weight: 700;
    
}
.cardDescription{
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to show */
    -webkit-box-orient: vertical;
    /* max-height: 1.5rem; */
    min-height: 6.5rem;
  line-height: 1.625rem; /* Adjust based on your line height */
}

@media (max-width: 768px) {
  .container{
    padding: 20px 0px 30px 0px;
}
     .benifitsContainer {
        width: 90%;
        
      }
      .benifitsHeading{
        font-size: 24px;
        line-height: 120%;
        }
        .benifitsSubHeading{
          font-size: 30px;
          font-weight: 700;
          line-height: 120%;
          margin: 0px;
      }

        .customCard {
            width: 280px;
          }
        
          .scrollableContainer {
            
            overflow-x: auto;
            white-space: nowrap;
            display: flex;
            
          }
          
        
          .cardsContainer {
            display: inline-flex;
            flex: 0 0 auto; /* Allow cards to shrink when necessary */
            margin-right: 10px; 
            width: auto;
          }
          .cardTitle{
            font-size: 16px;
            font-weight: 700;
            
          }
          .cardDescription{
            font-size: 14px;
          }
}

@media (min-width: 769px) and (max-width: 991px) {
    .benifitsContainer{
      width: 90%;
      
    }
    .customCard {
        width: 280px;
      }
      
      .scrollableContainer {
        
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        
      }
      
      
      .cardsContainer {
        display: inline-flex;
        flex: 0 0 auto; /* Allow cards to shrink when necessary */
        margin-right: 10px; 
        width: auto;
      }
      .cardTitle{
        font-size: 16px;
        font-weight: 700;
        
      }
      .cardDescription{
        font-size: 14px;
      }
      .benifitsHeading{
      font-size: 30px;
      line-height: 140%;
      }
}

@media (min-width: 992px) and (max-width: 1199px) {

}