



.linkTitle{
    font-size: 20px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-align: start;
}
.linkText{
    font-size: 20px;
    font-size: 14px;
    font-weight: 400;
    color: black !important;
    cursor: pointer;
    text-decoration: none !important;
}

/* pt-5 pb-3 d-flex flex-column justify-content-center align-items-center */
.footer{
    background-color: #F8F9FE;
    padding-top: 3rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: calc(100%-60px); */
}

/* .footer-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;

    
} */

.deskalaLogo{
    width: 200px;
    height: 70px;
    margin-bottom: 0px;
}

.deskalaAddress{
    color: #000;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    
}

.powerdby{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 166%; /* 26.56px */
text-align: start;
margin: 0;
}
.deskalaWebsite{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 166%; /* 26.56px */
text-align: start;
margin: 0;
}
.deskalaAnchorTag{
    color: #000;;
    list-style: none;
    text-decoration: none;
    
}

.copyrightsContainer{
    background-color: #222222;
    /* padding: 10px; */
    /* height: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}
.copyrightsText{
    font-size: 14px;
    font-weight: 400;
    font-family: 'poppins', Lato;
    color: #fff;
    margin: 0;
}
.socialShareLogo{
    width: 2rem;
}


@media screen and (min-width:992px) and (max-width:1199px) {
    .deskalaLogo{
        width: 200px;
        height: 70px;
    }
    .deskalaAddress{
        font-size: 18px;
    }
    .linkTitle{
        font-size: 18px;
        font-size: 14px;
    }
    .socialShareLogo{
        width: 1.75rem;
        margin-bottom: 10px;
    }
}

@media screen and (min-width:769px) and (max-width:991px) {
    .deskalaLogo{
        width: 150px;
        height: 60px;
    }
    .deskalaAddress{
        font-size: 15px;
        font-size: 14px;
    }
    .linkTitle{
        font-size: 15px;
    }
    .socialShareLogo{
        width: 1.75rem;
        margin-bottom: 10px;
    }
}

@media screen and (max-width:768px) {
    .deskalaLogo{
        width: 150px;
        height: 60px;
    }
    .deskalaAddress{
        font-size: 15px;
        text-align: start;
    }
    .linkTitle{
        font-size: 15px;
        font-size: 14px;
    }
    .socialShareLogo{
        width: 1.5rem;
        margin-bottom: 10px;
    }
    .copyrightsText{
        font-size: 12px;
        
    }
    .mobileRes{
        margin-top: 10px;
    }
}