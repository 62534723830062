.container{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
}
.subContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 40px;
}
.title{
    color: #000;
font-family: Lato;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 56px */
margin: 0px;
}
.subTitle{
    color: #000;
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 44.8px */
margin: 0px;
}

.scrollableContainer {
    
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    
  }
  
  .cardsContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    
}

  .anchorTag{
    text-decoration: none;
    color: #000;
    
  }

.marketingContainer{
    width: 75%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    border: 1px solid red;
}
.marketingCard{
    padding: 10px 3px;
    width: 350px;
    margin-top: 5px;
    margin-bottom: 5px;
    /* height: 240px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    gap: 5px;
    background: #FFFFFF;
    box-shadow: 1px 4px 8px 1px #0000000F;



    
}

.marketingTitle{
    font-size: 36px;
    font-weight: 700;
    word-wrap: break-word;
    white-space: normal; 
    overflow-wrap: break-word;
    margin: 0px;

    
}
.marketingImageContainer{
    width: 100%;
    
}
.marketingImage{
    width: 100%;
    
}
.publishBy{
    font-size: 14px;
    font-weight: 600;
    color: #000;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    margin: 5px 0px;
    padding: 0px 10px;
    opacity: 0.7;
   
}
.publishTitle{
    font-size: 20px;
    font-weight: 600;
    color: #005068 !important;
    word-wrap: break-word; 
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    text-align: start; 
    margin: 0px;
    padding: 5px 10px;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.publishDescription{
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    word-wrap: break-word;
    white-space: normal; /* Ensure normal white-space handling */
    overflow-wrap: break-word;
    margin: 0px;
    padding: 5px 10px;
    height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    
}
.button{
    color: #3B9FD6;
    font-size: 1rem;
    text-decoration: none ;
    padding: 5px 10px;
    
}
.badgesContainer{
    display: flex ;
    flex-direction: row;
    padding: 5px 0;

}
.badges{
    background-color: #d4d3d5;
    color: #000;
    padding: 2px 10px;
    font-size: 14px;
    font-weight: 500;
}
.buttonContainer{
border: 1px solid #005068;
padding:5px  10px;
margin:20px 0px;
text-decoration:none;

}
.buttonText{
    font-size: 1rem;
    color:#000;
    margin: 0;

}

@media (max-width: 768px) {
    .subContainer{
        width: 95%;
    }
    .title{
    font-size: 30px;
    line-height: 140%; /* 56px */

    }
    .subTitle{
    font-size: 24px;
    }
    .scrollableContainer {
    
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        
      }
      
    
      .cardsContainer {
        display: inline-flex;
        flex: 0 0 auto; /* Allow cards to shrink when necessary */
        margin-right: 10px; 
        width: auto;
      }
       .marketingCard {
        width: 240px;
      }
      .publishBy{
        font-size: 12px;
    }
      .publishTitle{
        font-size: 14px;
        height: 70px;
    }
    .publishDescription{
        font-size: 12px;
        line-height: 15px;
        height: 100px;
        
    }
    .badges{
        font-size: 10px;
        font-weight: 600;
    }
}

@media (min-width: 769px) and (max-width: 991px) {
    .subContainer{
        width: 95%;
    }
    .marketingCard {
        width: 300px;
      }
      .publishBy{
        font-size: 14px;
    }
      .publishTitle{
        font-size: 18px;
        height: 80px;
    }
    .publishDescription{
        font-size: 14px;
        line-height: 15px;
        min-height: 60px;
    }
}