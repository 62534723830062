.container{
    background: #F8F9FE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px 80px 10px;
}
.subContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}
.title{
    color: #000;
font-family: Lato;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 180%; /* 72px */
letter-spacing: 0.2px;
margin: 0px;
}
.desc{
    color: #000;
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 180%; /* 57.6px */
letter-spacing: 0.2px;
margin: 0px;
}
.iframe{
    margin-top: 15px;
    max-width: 100%; 
  }

  @media (min-width:789px) and (max-width:993px){
    .subContainer{
    
        width: 90%;
    }
    .title{
    font-size: 30px;
    }
    .desc{
    font-size: 24px;
    }
  }

  @media (max-width:768px){
    .container{
        padding: 30px 10px 50px 10px;
    }
    .subContainer{
    
        width: 95%;
    }
    .title{
    font-size: 28px;
    line-height: 130%;
    margin-bottom: 10px;
    }
    .desc{
    font-size: 22px;
    line-height: 120%;
    }
  }