.container {
  background-color: #fff;
  margin-top: 80px;
  padding-top: 4rem;
  padding-bottom: 50px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
}
.subContainer {
  width: 90%;
  display: flex;
  flex-direction: column;

  /* border: 1px solid black; */
  /* gap: 50px; */
}

.welcomeSubContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
  }
  
  .rainbow {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .headingContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-bottom: 180px;
  }
  
  .heading {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 70px */
    text-align: start;
    margin: 0px;
  }
  
  .skalebotParagraph {
    color: #000;
    width: 50%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28.26px */
    text-align: start;
    margin: 18px 0px 18px 0px;
  }
  .qr{
    display: flex;
    justify-content: flex-start;
  }


.qrContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.qrDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.scanButton {
  width: 180px;
  height: auto;
  padding: 10px 0px;
  /* flex-shrink: 0; */
  border-radius: 6px;
  background: #111;
  color: #fff;
  font-size: 16px;
  border: 0px;
}
.phoneImg {
  /* width: 164.138px; */
  height: 332.668px;
  /* flex-shrink: 0; */
  /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.10)); */
}




.icon {
  color: white; /* Adjust the color as needed */
  font-size: 24px; /* Adjust the size of the icon */
}


.welcomeThirdSubContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8fa;
  padding: 10px;
  width: 100%;
  gap: 10px;
  width: 100%;
}
.thirdSubInsideContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.mobileThirdSubInsideContainer {
  display: none;
}

.statics {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  background: #fff;
  padding: 30px 10px;
  cursor: pointer;
}

.staticsIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.staticsNum {
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 0px;
  color: #05c1a0;
}

.staticsDesc {
  color: #303030;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0px;
}

.welcomeCardsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 60px;
  }
  
  .welcomeCards {
    stroke-width: 1px;
    stroke: #f8f8fa;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 10px 40px 10px;
    gap: 10px;
    position: relative;
    width: 20%;
    cursor: pointer;
  }
  
  .welcomeCardsTile {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 1.8rem */
    margin: 0px;
    min-height: 50px;
  }
  
  .hoveredText {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    margin: 0px;
    min-height: 50px;
  }
  .bottomImg {
    position: absolute;
    left: 0;
    bottom: 0;
  }



@media (min-width: 992px) and (max-width: 1199px) {
  .subContainer {
    width: 85%;
  }
  .welcomeSubContainer {
    width: 100%;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* align-items: center; */
    gap: 20px;
    /* align-items: center; */
    padding-bottom: 150px;
  }


  .staticsDesc {
    font-size: 16px;
  }

  .headingContainer {
    width: 100%;
    padding-bottom: 20px;
    align-items: flex-start;
  }

  .heading {
    font-size: 26px;
    text-align: center;
  }

  .skalebotParagraph {
    width: 100%;
    padding: 0px 0px;
    text-align: start;
    width: 80%;
  }

  .qr {
    width: 100%;
    display: flex;
    justify-content: start;
  }
 
  
 
}
@media (min-width: 769px) and (max-width: 991px) {
  .subContainer {
    width: 90%;
  }
  .welcomeSubContainer {
    width: 100%;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    gap: 20px;
    /* align-items: center; */
    padding-bottom: 10px;
  }



  .staticsDesc {
    font-size: 16px;
  }

  .headingContainer {
    width: 100%;
    padding-bottom: 20px;
    align-items: center
  }

  .heading {
    font-size: 26px;
    text-align: center;
  }

  .skalebotParagraph {
    width: 100%;
    padding: 0px 0px;
    text-align: center;
    width: 80%;
  }

  .qr {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .rainbow {
    display: none;
  }
  .welcomeCardsContainer {
    width: 90%;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 10px;
    height: auto;
  }

  .welcomeCards {
    width: 30%;
    flex: 0 0 auto;
  }
 
  .thirdSubInsideContainer {
    display: none;
  }
  .mobileThirdSubInsideContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 90%;
  }
  .mobileStatics {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }

  .mobileStaticsDesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .staticsIconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .staticsNum {
    font-size: 16px;
  }
  .welcomeThirdSubContainer {
    margin-top: 10px;
  }
}
@media (max-width: 768px) {
  .container {
    padding-top: 2rem;
    padding-bottom: 30px;
  }
  .subContainer {
    width: 95%;
  }
  .welcomeSubContainer {
    width: 100%;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    gap: 20px;
    /* align-items: center; */
    padding-bottom: 10px;
  }



  .staticsDesc {
    font-size: 16px;
  }

  .headingContainer {
    width: 100%;
    padding-bottom: 20px;
    align-items: center
  }

  .heading {
    font-size: 26px;
    text-align: center;
  }

  .skalebotParagraph {
    width: 100%;
    padding: 0px 0px;
    text-align: center;
    width: 80%;
  }

  .qr {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .rainbow {
    display: none;
  }

  .headingContainer {
    width: 100%;
    /* border: 1px solid red; */
  }

  .qrContainer {
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .scanButton {
    width: 160px;
    font-size: 14px;
  }
  .welcomeCardsContainer {
    width: 90%;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 10px;
    height: auto;
    margin-top: 20px;
  }

  .welcomeCards {
    width: 80%;
    flex: 0 0 auto;
  }

 

  
  .thirdSubInsideContainer {
    display: none;
  }
  .welcomeThirdSubContainer {
    margin-top: 10px;
  }
  .mobileThirdSubInsideContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 90%;

  }
  .mobileStatics {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }

  .mobileStaticsDesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .staticsIconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .staticsNum {
    font-size: 16px;
  }
}
