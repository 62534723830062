.container{
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
    gap: 20px;
}

.heading{
    color: #000;
font-family: Lato;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0px;
}
.subHeading{
    color: #000;
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0px;
}
.plansContainer{
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    margin-top: 30px;

}
.onboardingContainer{
    display: flex;
    flex-direction: column;
    padding: 10px 10px 30px 10px;
    background: #F6F8FA;
    gap: 30px;
    width: 100%;
    margin-top: 10px;
}
.onboardingHeading{
    color: #000;
font-family: Lato;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 33.6px */
margin: 0px;
text-align: start;
padding: 0px 10px;
}
.listContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 30px 10px 10px; */
}
.customOl {
    list-style-type: lower-roman;
        padding-left: 28px;


  }
  
  .customOl li {
    position: relative;
    padding-left: 5px;
    /* margin-left: 10px; */
  }
  
.listItem{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
margin: 0px;
text-align: start;
}

.questionmarkcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: 30%;
}
.questionMark{
    color: #000;
width: 60px;
height: 60px;

}
.button{
    background: #000;
    color: #fff;
    padding: 10px 40px;
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
border: none;
border-radius: 10px;
}

.noteContainer{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.noteSubContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.noteHeading{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 22.4px */
margin: 0px;
}
.note{
    color: #000;
font-family: Lato;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
margin: 0px;
text-align: start;
}

@media (min-width:993px) and (max-width:1199px){
    .onboardingContainer{
        gap: 20px;
    }
   
    .onboardingHeading{
    font-size: 24px;
    
    }
    .listItem{
    font-size: 14px;
    }
    .noteHeading{
    font-size: 14px;
    }
   
    .questionmarkcontainer{
        gap: 20px;
    }
    .questionMark{
    width: 50px;
    height: 50px;
    
    }
    .button{
        padding: 10px 15px;
    font-size: 14px;
    }
    
}

@media (min-width:769px) and (max-width:992px){
    .subContainer{
        width: 90%;
        
    }
    .scrollableContainer {
    
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        
        
      }
      
    
      /* .plansContainer {
        display: inline-flex;
        flex: 0 0 auto; 
        margin-right: 10px; 
        width: auto;
        justify-content: flex-start;
      } */

      .plansContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        
    }
    .plansContainer {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-top: 10px;
        padding-top: 20px;
    }
    .plansContainer > * {
        flex: 0 0 auto;
        margin-right: 10px;
    }
    .onboardingContainer{
        gap: 20px;
    }
    .onboardingHeading{
        font-size: 24px;
        
        }
        .listItem{
        font-size: 14px;
        }
        .noteHeading{
        font-size: 14px;
        }
       
        .questionmarkcontainer{
            gap: 20px;
        }
        .questionMark{
        width: 50px;
        height: 50px;
        
        }
        .button{
            padding: 10px 0px ;
        font-size: 12px;
        width: 100px;
        }
}

@media (max-width:768px){

    .subContainer{
        width: 95%;
        
    }
    .heading{
    font-size: 28px;
    }
    .subHeading{
    font-size: 24px;
    }
    /* .scrollableContainer {
    
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        border: 1px solid red;
        
      } */
      /* .plansContainer {
        display: inline-flex;
        flex: 0 0 auto; 
        margin-right: 10px; 
        width: auto;
        gap: 10px;
      } */
      .plansContainer {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-top: 10px;
        padding-top: 20px;
    }
    .plansContainer > * {
        flex: 0 0 auto;
        margin-right: 10px;
    }
    .onboardingContainer{
        gap: 15px;
    }

    .onboardingHeading{
        text-align: center;
        font-size: 20px;
        line-height: 120%;
    }
    .listItem{
    font-size: 14px;
    }
    .questionmarkcontainer{
        display: none;
    }
    .noteHeading{
        font-size: 14px;
        }
       
}

 