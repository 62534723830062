.container{
    background: #F8F9FE;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subContainer{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.whatsappContainer{
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 10px 25px;
      display: flex;
      flex-direction: row;
      /* padding: 10px; */
      justify-content: space-around;
      align-items: center;
      /* width: 75%; */
     
  }
  .qrDetails{
      display: flex;
      flex-direction: column;
      padding: 20px;
      justify-content: center;
      align-items: flex-start;
      /* width: 75%; */
      gap: 20px;
      
  }
  .qrTitle{
      font-size: 50px;
      font-weight: 400;
      word-wrap: break-word;
      text-align: start;
      white-space: normal; /* Ensure normal white-space handling */
      overflow-wrap: break-word;
      /* width: 70%; */
      color: #000;
      line-height: 100%; /* 70px */
      margin: 0px;
  }
  .qrSubTitle{
      font-size: 28px;
      font-weight: 500;
      word-wrap: break-word;
      white-space: normal; /* Ensure normal white-space handling */
      overflow-wrap: break-word;
      text-align: start;
      margin: 0px;
      color: #000;
      /* width: 60%; */
  }
  .qrmobileTitle{
      font-size: 14px;
      font-weight: 400;
      color: #7B6DF8;
      color: #000;
      margin: 0px;
  }
  .storeContainer{
      display: flex;
      flex-direction: row;
      /* padding: 10px; */
      /* justify-content: space-around; */
      align-items: center;
  }
  .mobileImg{
    display: none;
}
    
    

    @media (max-width: 768px) {

        .subContainer{
            width: 95%;  
        }
        .whatsappContainer{
        width: 100%;
        flex-direction: column-reverse;
        }
        .qrDetails{
            align-items: center;
        
        }
        .qrTitle{
            font-size: 30px;
            text-align: center;
            font-weight: 500;
        }
        .qrSubTitle{
          font-size: 24px;
          text-align: center;
          font-weight: 500;
        }
        .DeskImg{
            display: none;
        }
        .mobileImg{
            display: block;
        }
        

    }

    @media (min-width: 769px) and (max-width: 991px) {
        .subContainer{
            width: 90%;  
        }
        .whatsappContainer{
        width: 100%;
        flex-direction: column-reverse;
        }
          .qrDetails{
            align-items: center;
          
          }
          .qrTitle{
            font-size: 30px;
            text-align: center;
          }
          .qrSubTitle{
            font-size: 24px;
            text-align: center;
            
          }
         
          .button{
            padding: 5px 10px;
            font-size: 10px;
            margin-top: 10px;
          }
          .DeskImg{
            display: none;
        }
        .mobileImg{
            display: block;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        /* .qrDetails{
            display: flex;
            flex-direction: column;
            padding: 20px;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            border: 1px solid red;
            
        } */
        .qrTitle{
            font-size: 40px;
            line-height: 100%;
        }
        .qrSubTitle{
            font-size: 24px;
            margin-top: 10px;
        }
        .mobileImg{
            display: none;
        }
    }