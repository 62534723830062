

.custom-chatbot-modal {
    position: fixed !important ;
    bottom: 20px !important ;
    right: 20px !important;
    z-index: 1;
    background: #fff;
    border: 1px solid #000;
    border-radius: 10px;
    width: 300px;
    
    /* z-index: 99999999; */
    /* width: auto; */
  }
  
  .botModalBody{
    position: relative;
    flex: 1 1 auto;
    padding: 0;
    width: 100%;
    /* z-index: 99999999; */
    
  }
  .chatbotOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    /* pointer-events: none;  */
  }
  
  .headerContainer{
    width: 100%;
    padding: 10px 30px 10px 25px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    gap: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px ;
  
  }
  .cancelIcon{
    color: #fff;
    font-size: 20px;
    margin: 0px;
   padding: 0px; 
    cursor: pointer;
  }
  .chat-content {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 0px 30px 10px;
    border-bottom: 0.5px solid #bfbfbf;
    background-color: #e5ddd5;
  min-height: 200px;
  background-image: url("../../images/chatbot/whatsappBackground.svg");
  }
  .messageContainer{
    width: 75%;
      margin: 15px;
      background: white;
      display: inline-block;
      padding: 10px 15px ;
      border-radius: 0px 8px 8px 8px;
      font-size: 14px;
      position: relative;
      z-index: 1;
  }
  .messageContainer ::before{
    content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-bottom: solid 10px transparent;
      border-right: solid 8px white;
  }
  .company{
    color: #848484;
    font-weight: 500;
    text-align: start;
  }
  .message{
    color: black ;
    margin-top: 6px ;
    white-space: pre-wrap ;
    min-width: 160px ;
    max-width: 250px ;
    margin-bottom: 0px ;
    word-break: break-word ;
    line-height: 1.4 ;
    border: none ;
    padding: 0 ;
    background: none ;
    text-align: start;
    font-family: Lato;
  }
  .list{
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    color: #000;
    margin: 0px;
    
  }
  .listItem{
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    color: #000;
    border: 0.5px solid #000;
    border-radius: 9px;
    padding: 4px 4px 4px 12px;
    width: 70%;
    margin: 0px;
    cursor: pointer;
  
  }
  .cursor{
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    height: 35px;
  }
  .deskala{
    font-size: 16px;
    margin: 0px;
    padding: 0px;
    color: #fff;
  }
  .online{
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    color: #fff;
    text-align: start;
  }
  
  .bottomContainer{
    width: 100%;
    padding: 20px 0px 20px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  
  .botInputContainer{
    width: 70%;
  }
  
  .inputField{
    width: 100%;
    font-size: 14px;
    font-weight:500;
    color: #2e2e2e;
    background: #bfbfbf;
    border: 0px;
    padding: 5px;
    border-bottom: 1px solid  #eae7e7;
    height: 35px;
  
  }
  
  .sendButton{
    font-size: 14px;
    font-weight: 500;
    padding: 2px 6px;
    background: #fff;
    color: #2a2a2a;
    margin: 0;
    outline: none;
    border: 1px solid #2a2a2a;
  
  }
  