.customPlanContainer {
  border-radius: 0.25rem;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(28, 115, 142, 0.20);
  width: 26%;
  display: flex;
  flex-direction: column;
  padding: 5px 0px 200px 0px;
}
.PlanNameContainer {
  padding: 20px 10px;
}
.PlanName {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 0px;
}
.pricingContainer {
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 50px 10px;
  height: 150px;
}
.pricingdetails {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 1.225rem */
  margin: 0px;
  padding: 0px 15px;
  text-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  height: 100px;

}

.button {
  background: #000;
  color: #fff;
  padding: 8px;
  border-radius: 0.25rem;
  border: 1px solid #005068;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.4rem */
  letter-spacing: 0.0125rem;
  width: 60%;
  
}

.serviceContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 5px 10px 10px;
}
.services {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  text-align: start;
}

.servicesListItem {
  color: #303030;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
  text-wrap: wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: start;
}
.plansCheck{
    margin-top: 2px;
  }







@media screen and (min-width: 993px) and (max-width: 1199px) {
  .customPlanContainer {
    width: 30%;
  }
  .PlanName {
    font-size: 1.25rem;
  }
  .services {
    font-size: 0.875rem;
  }
  .servicesListItem {
    font-size: 0.875rem;
  }
 
  
}
@media (min-width: 768px) and (max-width: 992px) {
  .PlanName {
    font-size: 1rem;
  }
  .pricingdetails {
    font-size: 0.75rem;
    padding: 0px;
  }
 
  .services {
    font-size: 0.75rem;
  }
  .servicesListItem {
    font-size: 0.75rem;
  }
 
}

@media (max-width: 768px) {
  .customPlanContainer {
    width: 280px;
    max-width: 320px;
  }
  .PlanName {
    font-size: 1.5rem;
  }
  .pricingdetails {
    font-size: 0.875rem;
    padding: 0px;
  }
  .services {
    font-size: 0.875rem;
  }
  .servicesListItem {
    font-size: 0.875rem;
  }
  
  
}
