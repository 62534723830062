.container{
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 120px 10px 50px 10px;

}

.subContainer{
    width: 75%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;
    /* border: 1px solid red; */
}

.heading{
    color: #000;
font-family: Lato;
font-size: 2.5rem;
font-style: normal;
font-weight: 400;
line-height: 140%; 
margin: 0px;
width: 100%;
text-align: center;
/* border: 1px solid red; */
}
.termsContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 5px; */
}
.terms{
    color: #000;
font-family: Lato;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 140%; 
margin: 0px;
/* border: 1px solid red; */
width: 100%;
text-align: start;

}
@media (min-width:993px) and (max-width:1199px) {
    .subContainer{
        width: 85%;
    }
    .heading{
    font-size: 2.25rem;
    }
    .terms{
    font-size: 1rem;
    }
}
@media (min-width:769px) and (max-width:992px) {
    .subContainer{
        width: 90%;
        gap: 15px;
    }
    .heading{
    font-size: 2rem;
    }
    .terms{
    font-size: 1rem;
    }
}
@media  (max-width:768px) {
    .subContainer{
        width: 95%;
        gap: 10px;
    }
    .heading{
    font-size: 1.5rem;
    }
    .terms{
    font-size: .875rem;
    }
}
