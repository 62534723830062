.standardPlanContainer {
    border-radius: 0.25rem;
    border: 1px solid #f0f0f0;
    background: #fff;
    box-shadow: 0px 4px 6px 0px rgba(62, 167, 214, 0.20);
    width: 26%;
    display: flex;
    flex-direction: column;
    padding: 5px 0px 20px 0px;
  }
  .PlanNameContainer {
    padding: 20px 10px;
  }
  .PlanName {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin: 0px;
  }
  .pricingContainer {
    background: #3EA7D6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 10px;
    height: 150px;
  }
  .pricingdetails {
    color: #fff;
    text-align: center;
    font-family: Lato;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 1.225rem */
    margin: 0px;
    /* padding: 0px 15px; */
    text-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
  
  .amount {
    color: #fff;
    font-family: Lato;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
  }
  
  .rupee {
    font-size: 1rem;
  }
  
  .conversationContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px 10px;
    height: 100px;
  }
  .conversation {
    color: #303030;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
    text-align: center;
  }
  
  .rechargeText {
    color: #303030;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    padding: 5px;
    text-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
  
  .serviceContainer {
    display: flex;
    flex-direction: column;
    padding: 10px 5px 10px 10px;
  }
  .services {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
    text-align: start;
  }
  
  .servicesListItem {
    color: #303030;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    text-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    text-align: start;
  }
  .plansCheck{
      margin-top: 2px;
    }
  
  @media screen and (min-width: 993px) and (max-width: 1199px) {
    .standardPlanContainer {
      width: 30%;
    }
    .PlanName {
      font-size: 1.25rem;
    }
    .amount {
      font-size: 1.5rem;
    }
    .rupee {
      font-size: 0.875rem;
      height: 20px;
    }
    .services {
      font-size: 0.875rem;
    }
    .servicesListItem {
      font-size: 0.875rem;
    }
   
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .PlanName {
      font-size: 1rem;
    }
    .pricingdetails {
      font-size: 0.75rem;
      /* padding: 0px 10px; */
    }
    .amount {
      font-size: 1.25rem;
    }
    .rupee {
      height: 15px;
      width: 15px;
    }
    .services {
      font-size: 0.75rem;
    }
    .servicesListItem {
      font-size: 0.75rem;
    }
    
  }
  
  @media (max-width:768px){
      .standardPlanContainer {
          width: 280px;
          max-width: 320px;
        }
        .PlanName {
          font-size: 1.5rem;
        }
        .pricingdetails {
          font-size: 0.875rem;
          /* padding: 0px 10px; */
        }
        .amount {
          font-size: 1.5rem;
        }
        .rupee {
          height: 20px;
          width: 15px;
        }
        .services {
          font-size: 0.875rem;
        }
        .servicesListItem {
          font-size: 0.875rem;
        }
        
  }
  