.container{
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactUsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 80%;
    padding: 40px 10px;

    }
    .contactUsTitle{
        font-size: 40px;
        font-weight: 700;
        word-wrap: break-word;
        margin-bottom: 30px;
    }
    .subContainer{
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        width: 100%;
        gap: 80px;
    }
    .imgContainer{
        width: 50%;
    }
    
    .formContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 30%;
        /* margin-bottom: 5rem; */
        /* padding: 30px; */
        padding: 0px 10px;
        /* gap: 20px; */
        border: 1px solid black ;
        border-radius: 6px;
        
    }
    
    .inputDiv{
        width: 100%;
        height: auto;
    }
    
    .inputStyle{
        width: 100%;
        padding: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.6px;
        color: #000;
        opacity: 0.7;
        background-color: black;
        border: 1px solid #000;
        border-radius: 6px;
        /* outline-color: black; */
        
    }
    
    .submitButton{
        font-weight: 300;
        font-size: 18px;
        color: #FFF;
        background-color: #000;
        border: none;
        border-radius: 6px;
        padding: 5px 20px ;
        width: 100%;
    
    }
    @media (max-width: 768px) {
        .contactUsContainer{
            width: 95%;
            padding: 20px 10px;

            }
        .formContainer{
            flex-direction: column;
            gap: 20px;
            /* margin-bottom: 5px; */
            padding: 10px 10px 0px 10px;
            
        }
        .contactUsTitle{
            font-size: 30px;
        }
        
        .formContainer{
            width: 100%;
            /* margin-bottom: 5rem; */
            padding: 20px 10px 10px 10px;
            
        }
        .imgContainer{
            display: none;
        }
        .inputDiv{
            width: 100%;
        }
        .submitButton{
            width: 100%;
            padding: 5px;
        
        }
       
    }

    @media (min-width: 769px) and (max-width: 991px) {
        .contactUsContainer{
            width: 90%;
            padding: 20px 10px 0px 10px;
        
            }
        .formContainer{
            flex-direction: column;
            /* gap: 40px; */
            /* margin-bottom: 5px; */
          }
          
          .inputDiv{
            width: 100%;
        }
        .submitButton{
            width: 100%;
        
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {

        .contactUsContainer{
            padding: 20px 10px 20px 10px;
            width: 90%;
        
            }
            .inputDiv{
                width: 100%;
            }
            .submitButton{
                width: 100%;
            
            }
    }