.container{
    background: #F8F9FE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px  40px 80px 40px ;
}

.subContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 80%;
}

.heading{
    color: #000;
font-family: Lato;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0px;
}

.subHeading{
    color: #000;
font-family: Lato;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0px;
}
.cardContainer{
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 2px 2px 10px 2px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px #00000026;

}
.cardImg{
    width: 100%;
}
.cardName{
    color: #000;
font-family: Lato;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 33.6px */
margin: 0px;
}
.cardPosition{
    color: #9C9C9C;
text-align: center;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 22.4px */
margin: 0px;
}
.contactDetailsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}
.contactContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.contactDetails{
    color: #000;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 152.023%; /* 24.324px */
margin: 0px;
}

@media (max-width:768px){
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
    }
    
    .subContainer{
        width: 95%;
        gap: 10px;
    }
    .heading{
    font-size: 30px;
}
.subHeading{
font-size: 24px;
}
.scrollableContainer {
    
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    margin-top:30px;
    
  }
.cardContainer{
    /* display: flex; */
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    display: inline-flex;
    flex: 0 0 auto; 
    margin-right: 10px; 
    width: auto;
}
/* .cardContainer {
    display: inline-flex;
    flex: 0 0 auto; 
    margin-right: 10px; 
    width: auto;
  } */
  .card{
    width: 240px;
  }
  .cardName{
font-size: 20px;
line-height: 100%; /* 33.6px */
}
.cardPosition{
font-size: 14px;
line-height: 100%; /* 33.6px */
}
.contactDetails{
font-size: 14px;
}
}

@media (min-width:769px) and (max-width:991px){
    .subContainer{
        width: 90%;
        gap: 10px;
    }
    .heading{
    font-size: 30px;
    line-height: normal ;
}
.subHeading{
font-size: 24px;
line-height: normal ;
}
.scrollableContainer {
    
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    
  }
.cardContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 20px;
}
/* .cardContainer {
    display: inline-flex;
    flex: 0 0 auto;
    margin-right: 10px; 
    width: auto;
  } */
  /* .card{
    width: 240px;
  } */
  .cardName{
font-size: 20px;
line-height: 100%; /* 33.6px */
}
.cardPosition{
font-size: 14px;
line-height: 100%; /* 33.6px */
}
.contactDetails{
font-size: 14px;
}
}
@media (min-width:992px) and (max-width:1199px){
    .cardPosition{
    font-size: 14px;
    line-height: 100%; /* 22.4px */
    }

}